<template>
	<ZyroModal
		height="100%"
		max-width="758px"
		max-height="600px"
		position="bottom"
		class="building-modal"
		@close-modal="closeModal"
	>
		<div class="building-modal__wrapper">
			<h4 class="building-modal__title z-h4">
				{{ $t('builder.curatedTemplates.startBuilding.title') }}
			</h4>
			<i18n
				class="building-modal__text z-body"
				path="builder.curatedTemplates.startBuilding.text"
				tag="p"
			>
				<span>{{ $t('builder.curatedTemplates.startBuilding.slots.section') }}</span>
				<span>{{ $t('builder.curatedTemplates.startBuilding.slots.elements') }}</span>
				<span>{{ $t('builder.curatedTemplates.startBuilding.slots.styles') }}</span>
				<span>{{ $t('builder.curatedTemplates.startBuilding.slots.publish') }}</span>
			</i18n>
			<img
				class="building-modal__image"
				src="@/assets/images/start-building-designer.png"
			>
			<ZyroButton
				class="building-modal__button"
				data-qa="builder-buildingmodal-btn-continue"
				theme="primary"
				color="plump-purple"
				size="xs"
				@click="closeModal"
			>
				{{ $t('common.continue') }}
			</ZyroButton>
		</div>
	</ZyroModal>
</template>

<script>
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

export default { methods: { ...mapActionsGui({ closeModal: CLOSE_MODAL }) } };
</script>

<style lang="scss" scoped>

@mixin font-settings {
	max-width: 578px;
	font-family: $font-family;
	text-align: center;
}

.building-modal {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 64px 0 40px 0;
	}

	&__title {
		margin-bottom: 24px;

		@include font-settings;

		@include mobile-view {
			margin-bottom: 26px;
		}
	}

	&__text {
		@include font-settings;

		span {
			font-weight: 700;
		}
	}

	&__image {
		width: 100%;
	}

	&__button {
		margin: 0;
		overflow: hidden;
	}
}
</style>
